import {mapActions, mapGetters, mapMutations} from "vuex";
import {validationMixin} from "vuelidate";
import {email, maxLength, minLength, required, sameAs} from "vuelidate/lib/validators";
import VueHead from 'vue-head'
import Vue from 'vue'

Vue.use(VueHead)

export default {
  name: "feedback",
  mixin: [validationMixin],
  data() {
    return {
      payload: {
        name: '',
        email: '',
        message: '',
        checkbox: false,
      },
      params: {
        title: '',
        description: '',
        keywords: '',
        image: ''
      },
      validationErrors: {},
    }
  },
  head: {
    title: function () {
      return {
        inner: this.params.title !== '' ? this.params.title : '',
        separator: ' ',
      }
    },
    meta: function () {
      return [
        {n: 'keywords', content: this.params.keywords, id: 'keywords'},
        {p: 'og:title', content: this.params.title, id: 'og:title'},
        {p: 'og:description', content: this.params.description, id: 'og:description'},
        {n: 'title', content: this.params.title , id: 'title'},
        {n: 'description', content: this.params.description , id: 'description'},
        {p: 'og:image', content: this.params.image, id: 'og:image'},
      ]
    },
    link: function () {
      let route = this.$route.path
      if (route.slice(1, 4) === 'ru/' || route.slice(1, 4) === 'en/'){
        route = route.slice(3)
      }
      return [
        { rel: 'canonical', href: window.location.origin + this.$route.path, id: 'canonical' },
        {rel: 'alternate', hreflang: 'ru', href: window.location.origin + '/ru' + route, id: 'ru'},
        {rel: 'alternate', hreflang: 'en', href: window.location.origin+ '/en' + route, id: 'en'},
        {rel: 'alternate', hreflang: 'x-default', href: window.location.origin + route, id: 'x-default'},
      ]
    }
  },
  validations: {
    payload: {
      email: {
        required,
        email,
      },
      name: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(50),
      },
      message: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(500),
      },
      checkbox: {
        checked: value => value === true
      },
    }
  },
  created() {
    this.fetchPage('feedback')
    },
  watch: {
    page: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.setMeta()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      page: `pages/page`
    }),
    emailErrors() {
      let error = [];
      if (!this.$v.payload.email.$dirty) {
        return error;
      }
      if (!this.$v.payload.email.required) {
        error.push(this.$t('validationRequired'))
      }
      if (!this.$v.payload.email.email) {
        error.push(this.$t('validationEmail'))
      }
      if (this.validationErrors.email) {
        this.validationErrors.email.forEach((row) => {
          error.push(row);
        });
      }
      return error;
    },
    nameErrors() {
      let error = [];
      if (!this.$v.payload.name.$dirty) {
        return error;
      }
      if (!this.$v.payload.name.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.name.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 50));
      }
      if (!this.$v.payload.name.minLength) {
        error.push(this.$t('validationMin').replace(':count', 1));
      }
      if (this.validationErrors.name) {
        this.validationErrors.name.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
    messageErrors() {
      let error = [];
      if (!this.$v.payload.message.$dirty) {
        return error;
      }
      if (!this.$v.payload.message.required) {
        error.push(this.$t('validationRequired'));
      }
      if (!this.$v.payload.message.maxLength) {
        error.push(this.$t('validationMax').replace(':count', 500));
      }
      if (!this.$v.payload.message.minLength) {
        error.push(this.$t('validationMin').replace(':count', 5));
      }
      if (this.validationErrors.message) {
        this.validationErrors.message.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
    checkboxErrors() {
      let error = [];
      if (!this.$v.payload.checkbox.$dirty) {
        return error;
      }
      if (!this.$v.payload.checkbox.checked) {
        error.push(this.$t('validationRequired'));
      }
      
      if (this.validationErrors.checkbox) {
        this.validationErrors.checkbox.forEach((row) => {
          error.push(row);
        })
      }
      return error;

    },
  },
  methods: {
    ...mapActions({
      sendFeedback: `pages/SEND_FEEDBACK`,
      fetchPage: 'pages/GET_PAGE'
    }),
    resetForm() {
      this.payload.name = ''
      this.payload.email = ''
      this.payload.message = ''
      this.payload.checkbox = false
      this.$v.$reset();
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.sendFeedback(this.payload).then(() => {
          this.$toasted.success(this.$t('youMessagesSend'));
          this.resetForm()
        }).catch(error => {
          if (error.response.status === 400) {
            this.$toasted.error(this.$t("fail"));
          }
        })
      }

    },
    setMeta() {
      this.params.title = this.page.meta.meta_title !== '' ? this.page.meta.meta_title : 'ICHIBAN STORE',
        this.params.description = this.page.meta.meta_description !== '' ? this.page.meta.meta_description : 'ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE ICHIBAN STORE',
        this.params.keywords = this.page.meta.meta_keywords,
        this.params.image = this.page.image !== '' ? this.page.image : 'https://ichibanstore.jp/logo.png'
      this.$emit('updateHead')
    },
  }
}